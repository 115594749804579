import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer>
    <div className="w-full p-6 text-center border-t border-double border-tb">
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
        <Link to="/tags/" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">所有标签</Link>
      </div>
      <Link to="/">
      免责声明：该网站对非法色情内容采取零容忍政策，且本网站只适合十八岁或以上人士观看，我们有责任帮助父母限制未成年访问本网站，任何有未成年人的家庭在家中或在其监护下的人都应实施基本的家长控制保护措施，包括计算机硬件和设备设置、软件安装或使用 ISP 过滤服务，以阻止未成年人访问不当内容。
      </Link>  
    </div>
  </footer>
);

export default Footer;
